import React, { lazy, Suspense } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';
import updateLocale from 'dayjs/plugin/updateLocale';
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
import { AuthProvider } from './contexts/Auth/AuthProvider';

import { HashRouter } from 'react-router-dom';
import Main from '@/Main';
import Theme from '@/Theme';

const DrawerSearchWidget = lazy(() => import('@/widget/DrawerSearchWidget'));
const HuBOL = lazy(() => import('@/HuBOL'));


import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/pl';
import 'dayjs/locale/nl';
import 'dayjs/locale/fr';


dayjs.Ls.it.weekStart = 1;
dayjs.Ls.en.weekStart = 1;
dayjs.Ls.de.weekStart = 1;
dayjs.Ls.pl.weekStart = 1;
dayjs.Ls.fr.weekStart = 1;
dayjs.Ls.nl.weekStart = 1;

dayjs.extend(updateLocale);
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault('Europe/Rome');

declare global {
  interface Window {
    isBookingOnlinePage: boolean;
  }
}

function App() {

  console.log('render App');

  LicenseInfo.setLicenseKey(
    '52584ccceb73b1c914aa1faf93445415Tz0xMDMzMjYsRT0xNzY0NzUzNTQ1MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
  );

  return (
    <Suspense
      fallback={
        <Backdrop open={true} style={{ zIndex: 99 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      }>
      <I18nextProvider i18n={i18n}>
        <HashRouter>
          <Main>
            <AuthProvider>
              <Theme>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}>
                  {
                    window.isBookingOnlinePage ? (
                      <Suspense fallback={<Backdrop open={true} style={{ zIndex: 99 }}>
                        <CircularProgress color="inherit" />
                      </Backdrop>}>
                        <HuBOL />
                      </Suspense>
                    ) : (
                      <Suspense fallback={<Backdrop open={true} style={{ zIndex: 99 }}>
                        <CircularProgress color="inherit" />
                      </Backdrop>}>
                        <DrawerSearchWidget />
                      </Suspense>
                    )}
                </LocalizationProvider>
              </Theme>
            </AuthProvider>
          </Main>
        </HashRouter>
      </I18nextProvider>
    </Suspense>
  );
}

export default App;
